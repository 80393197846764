import { render, staticRenderFns } from "./about.vue?vue&type=template&id=4e6c232e&scoped=true&"
var script = {}
import style0 from "./about.vue?vue&type=style&index=0&id=4e6c232e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e6c232e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonSectionTitle: require('/home/circleci/repo/frontend/components/common/section-title.vue').default,CommonCustomImage: require('/home/circleci/repo/frontend/components/common/custom-image.vue').default,TopAccess: require('/home/circleci/repo/frontend/components/top/access.vue').default})
